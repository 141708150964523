import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/design/design/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
import ComponentLayout from '~/src/layouts/component-layout';
import { AccessibilityLink } from '~/src/components/accessibility-link';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = ComponentLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <img width="960" role="presentation" src="https://user-images.githubusercontent.com/2313998/224133512-4e6bd321-5ad4-4c5e-865e-9e7f6b444cbf.png" />
    <h2>{`Usage`}</h2>
    <p>{`Use radio group to allow users to select a single option from a short list of related options.`}</p>
    <h3>{`Orientation`}</h3>
    <p>{`A vertical orientation makes options easier to visually scan. A horizontal orientation is not yet supported.`}</p>
    <h3>{`Anatomy`}</h3>
    <img width="960" role="presentation" src="https://user-images.githubusercontent.com/2313998/224133509-5df6e212-ca18-4950-87c8-7c90236ce29e.png" />
    <p><strong parentName="p">{`Label:`}</strong>{` A title that labels the group of options and helps users understand the relationship between the options in the group`}</p>
    <p><strong parentName="p">{`Required indicator:`}</strong>{` Indicates that a selection is required`}</p>
    <p><strong parentName="p">{`Caption:`}</strong>{` May be used to provide additional context about the radio group when the label alone is not sufficient`}</p>
    <p><strong parentName="p">{`Options:`}</strong>{` A list of mutually exclusive options represented using `}<a parentName="p" {...{
        "href": "/components/radio"
      }}>{`radio buttons`}</a></p>
    <p><strong parentName="p">{`Validation message:`}</strong>{` A message explaining why the selection failed validation. See the `}<a parentName="p" {...{
        "href": "../ui-patterns/forms/overview#validation"
      }}>{`form pattern documentation`}</a>{` for more information on form validation patterns.`}</p>
    <h3>{`Best practices`}</h3>
    <ul>
      <li parentName="ul">{`Put radio buttons in a logical order`}</li>
      <li parentName="ul">{`Only use a radio group for a short list of options: aim for 6 or less options. For longer sets of options, consider using a `}<inlineCode parentName="li">{`<select>`}</inlineCode>{` input `}{/* TODO: update "`<select>` input" with a link to the Select component doc once it's created */}{`, an `}<a parentName="li" {...{
          "href": "/components/action-menu"
        }}>{`action menu`}</a>{`, or an `}<a parentName="li" {...{
          "href": "/components/autocomplete"
        }}>{`autocomplete input`}</a>{`.`}</li>
      <li parentName="ul">{`Radio buttons cannot be unchecked, so only use a radio group when a selection is required. Consider the following alternatives:`}
        <ul parentName="li">
          <li parentName="ul">{`use a radio group, but with one option that equates to "none of these options"`}</li>
          <li parentName="ul">{`use a `}<a parentName="li" {...{
              "href": "/components/checkbox-group"
            }}>{`checkbox group`}</a>{` that fails validation if more than one option is selected`}</li>
        </ul>
      </li>
      <li parentName="ul">{`If there is an option that's the most likely option to be checked, that option should be checked by default`}</li>
    </ul>
    <h2>{`Accessibility`}</h2>
    <ul>
      <li parentName="ul">{`Once a user checks an option from a radio group, the result should not be saved or applied until the user has explicitly submitted their input using a "save" or "submit" button. A radio group is a single tab stop, and the options can be navigated using the arrow keys: this means assistive technologies like a screen reader cannot "read" an option without selecting it. See the `}<a parentName="li" {...{
          "href": "../ui-patterns/saving"
        }}>{`saving pattern guidelines`}</a>{` for more information.`}
        <ul parentName="li">
          <li parentName="ul">{`Alternative: you can use a `}<a parentName="li" {...{
              "href": "/components/segmented-control"
            }}>{`segmented control`}</a>{` to immediately apply the selection as long as it's not in the context of a bigger form that applies other input values when submited`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Radio groups must be labeled. The label may be visually hidden, but visible labels are preferred.`}</li>
    </ul>
    <h3>{`Known accessibility issues (GitHub staff only)`}</h3>
 <AccessibilityLink label="RadioGroup" mdxType="AccessibilityLink" />
    <h2>{`Related links`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "../ui-patterns/forms/overview"
        }}>{`Forms`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/form-control"
        }}>{`Form control`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "../ui-patterns/saving"
        }}>{`Saving`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/radio"
        }}>{`Radio`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/checkbox"
        }}>{`Checkbox`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/checkbox-group"
        }}>{`Checkbox group`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      